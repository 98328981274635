@import '../variables.scss';

.react-contexify__item__content {
  & span {
    width: 20px;
    text-align: center;
    margin-right: 6px;

    svg {
      path {
        fill: $color-gray-4;
      }
    }
  }
}
