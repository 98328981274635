.psv-gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-y: auto;

  .psv-gallery-folders {
    width: 100%;
    margin-bottom: 12px;

    .psv-gallery-folder-tile {
      position: relative;
      display: inline-block;
      width: 128px;
      height: 140px;
      border: 1px solid transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 96px 96px;
      cursor: pointer;

      &:hover {
        border: 1px solid gray;
      }

      .psv-gallery-folder-tile-name {
        text-align: center;
        position: absolute;
        bottom: 8px;
        left: 0;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
      }
    }
  }

  .psv-gallery-file-tile {
    border: 1px solid transparent;
    margin-right: 4px;
    margin-bottom: 4px;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;

    &:hover {
      border: 1px solid gray;
    }

    // Needed for vertical centering.  See https://stackoverflow.com/questions/7273338.
    .psv-gallery-image-helper {
      display: inline-block;
      height: 100%;
      vertical-align: middle;

      // On smaller screens we don't use the helper.
      @media only screen and (max-width: 600px) {
        display: none;
      }
    }

    .psv-gallery-image-landscape {
      width: 100%;
      vertical-align: middle;

      // On smaller screens the containing div is square (see below).
      // We size the image to be larger than the container and position
      // to the left to achieve a cropped effect.
      @media only screen and (max-width: 600px) {
        width: 150%;
        margin-left: -25%;
      }
    }

    .psv-gallery-image-portrait {
      height: 100%;

      // On smaller screens the containing div is square (see below).
      // We size the image to be larger than the container and position
      // to the top to achieve a cropped effect.
      @media only screen and (max-width: 600px) {
        height: 150%;
        margin-top: -25%;
      }
    }

    &.psv-gallery-tile-small {
      width: 96px;
      height: 96px;
    }

    &.psv-gallery-tile-medium {
      width: 256px;
      height: 256px;

      // Optimize for iPad and similar tablets.
      @media only screen and (max-width: 1024px) {
        width: 240px;
        height: 240px;
      }
    }

    &.psv-gallery-tile-large {
      height: 1280px;
      width: 1280px;

      // Optimize for iPad and similar tablets.
      @media only screen and (max-width: 1024px) {
        width: 1000px;
        height: 1000px;
      }
    }

    // On smaller screens we use a square container to get a more dense
    // layout of images.  Container size is derived from screen size.
    @media only screen and (max-width: 600px) {
      width: 140px !important;
      height: 140px !important;
    }

    @media only screen and (max-width: 500px) {
      width: 125px !important;
      height: 125px !important;
    }

    @media only screen and (max-width: 400px) {
      width: 110px !important;
      height: 110px !important;
    }

    @media only screen and (max-width: 350px) {
      width: 95px !important;
      height: 95px !important;
    }
  }
}
