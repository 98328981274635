@import '../variables.scss';

.psv-breadcrumb-bar {
  white-space: nowrap;

  & > div {
    display: inline-block;
    margin-right: 8px;
  }

  .psv-breadcrumb-menuitem:hover {
    background-color: $color-gray-2;

    &.psv-breadcrumb-menuitem-selected {
      background-color: $color-gray-4;
    }
  }
  .psv-breadcrumb-menuitem-selected {
    background-color: $color-highlight;
  }

  .psv-breadcrumb {
    display: inline-block;
    vertical-align: bottom;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .psv-breadcrumb-separator {
    padding-left: 8px;
    padding-right: 8px;
  }

  .psv-breadcrumb-separator::after {
    content: '>';
  }
}
