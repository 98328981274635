@import '../variables.scss';

.psv-export-banner {
  height: 44px;
  background-color: $color-highlight;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    height: 20px;
    width: 20px;
    margin-right: 8px;
  }
}
