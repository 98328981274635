.psv-library-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid lightgray;

  .psv-library-name {
    flex: 1;
    font-size: 18px;
    font-weight: bold;
  }

  .psv-library-toolbar {
    height: 100%;
    white-space: nowrap;
  }

  .psv-library-toolbar-button {
    color: gray;
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    padding-top: 6px;
    padding-left: 8px;
    padding-right: 8px;

    &:hover {
      background-color: lightgray;
    }
  }
}
