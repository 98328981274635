body {
  /* Bootstrap fonts less Arial */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.navbar-brand {
  font-family: -apple-system, BlinkMacSystemFont, 'Montserrat', 'Segoe UI',
    Roboto, 'Helvetica Neue', 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.psv-error {
  color: red;
}

.psv-inline-form {
  &.container {
    margin-top: 40px;
    width: 400px;
    padding: 20px;
    border: 1px solid lightgray;
  }

  h5,
  p {
    text-align: center;
  }

  .psv-full-width-button {
    width: 100%;
    margin-top: 20px;
  }
}

.danger-text {
  color: red;
}

.user-icon {
  border-radius: 50%;
  background-color: #dddddd;
  color: black;
  width: 25px;
  height: 25px;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  padding-top: 3px;
}
