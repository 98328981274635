.psv-file-page-container {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .psv-file-page-header {
    background-color: lightgray;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .psv-file-toolbar-button {
      margin-right: 14px;
      cursor: pointer;

      &.psv-button-disabled {
        svg {
          color: grey;
        }
      }
    }
  }

  .psv-file-container {
    flex: 1;
    min-height: 0px;
    text-align: center;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}
