@import '../variables.scss';

.psv-folder-tree {
  overflow-y: auto;
  height: 300px;

  .list-group-group-root {
    padding: 0;
    overflow: hidden;
  }

  .list-group .list-group {
    margin-bottom: 0;
  }

  .list-group .list-group-item {
    border-radius: 0;
    border: 0;
    padding: 2px 10px;
  }

  .list-group > .list-group-item:first-child {
    border-top-width: 0;
  }

  .list-group > .list-group > .list-group-item {
    padding-left: 30px;
  }

  .list-group > .list-group > .list-group > .list-group-item {
    padding-left: 45px;
  }

  .psv-folder-item {
    cursor: default;

    &.psv-folder-item-selected {
      background-color: $color-highlight;
    }

    .psv-folder-expando {
      display: inline-block;

      &.psv-folder-chevron {
        color: $color-gray-4;
        width: 20px;
      }

      &.psv-folder-icon {
        color: $color-folder;
        width: 25px;
      }
    }
  }
}
